.root {
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem;
  border: solid 1px #ebecee;
  border-radius: 8px;
  padding: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.label {
  display: inline-block;
  min-width: 120px;
  user-select: none;
}

.details {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.action {
  margin-left: 10px;
}

.revoked {
  opacity: 0.4;

  & em {
    text-decoration: line-through;
  }
}
