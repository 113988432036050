.explorer-container {
  border-top: 1px solid lightgrey;
}

.graphiql-container {
  height: calc(100vh - 80px);
  width: 100%;
}

/* The graphiql-explorer package seems to ship with some weird CSS bugs that we need to fix here */
.graphiql-explorer-node {
  padding-left: 5px;
}
